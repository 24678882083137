<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col class="my-1">
        <b-button-group>
          <b-button @click.prevent="adddisposal" variant="primary">
            {{ $t('Add') }}
          </b-button>
          <!-- <xlsx-workbook>
            <xlsx-sheet :collection="items" sheet-name="Sheet1" />
            <xlsx-download filename="Export Data Disposal">
              <b-button v-if="allowCreate()" variant="success">
                {{ $t('Export') }}
              </b-button>
            </xlsx-download>
          </xlsx-workbook>
          <b-button v-if="allowCreate()" @click.prevent="addimport" variant="warning">
            {{ $t('Import') }}
          </b-button>
          <modal-import @getData="getData"></modal-import> -->
        </b-button-group>
      </b-col>
    </b-row>
    <b-card>
      <!-- <modal-disposal :item="currentItem" @loading="toggleLoading" @submit="submit" @getData="getData"></modal-disposal> -->

      <b-row>
        <b-col md="2" sm="6" class="my-1">
          <b-form-group class="mb-0" :label="$t('Per page')" label-size="sm" label-for="perPageSelect">
            <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" />
          </b-form-group>
        </b-col>
        <b-col md="2" sm="6" class="my-1">
          <b-form-group :label="$t('Sort')" label-size="sm" label-for="sortBySelect" class="mb-0">
            <b-input-group size="sm">
              <!-- <b-form-select id="sortBySelect" v-model="sortBy" :options="sortOptions" @change="getData()" class="w-75">
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
</b-form-select> -->
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" @change="getData()" class="w-100">
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="8" sm="12" class="my-1">
          <b-form-group :label="$t('Filter')" label-for="filterInput" class="mb-0" size="sm">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Tekan enter untuk cari" size="sm"
                @keydown.enter.prevent="getData()"></b-form-input>
              <b-input-group-append>
                <b-button variant="outline-primary" @click="getData()" size="sm">
                  {{ $t('Search') }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <template v-if="items.length > 0">
          <b-col cols="12">
            <b-table small hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
              :fields="fields">
              <template #cell(no)="row">
                <strong class="text-center">
                  {{ (row.index + 1) }}
                </strong>
              </template>
              <template #cell(nama_pic)="{ item }">
                {{ truncateString(item.nama, 15) }}<br>
                <small>{{ truncateString(item.no_hp_pic, 15) ? truncateString(item.no_hp_pic, 15) : "-" }}</small>
                <!-- <span :title="item.nama">{{truncateString(item.email, 15)}}</span><br> -->
              </template>
              <template #cell(nama_instansi)="{ item }">
                {{ truncateString(item.instansi, 15) }}<br>
                <small>{{ truncateString(item.nohp, 15) ? truncateString(item.nohp, 15) : "-" }}</small>
              </template>
              <template #cell(top)="{ item }">
                <span :title="item.top">{{ truncateString(item.top, 30) }}</span><br>
              </template>
              <template #cell(alamat)="{ item }">
                <span :title="item.alamat">{{ truncateString(item.alamat, 30) }}</span><br>
              </template>
              <template #cell(memo)="{ item }">
                <span :title="item.memo">{{ truncateString(item.memo, 30) }}</span><br>
              </template>
              <template #cell(kode)="{ item }">
                {{ item.penyimpanan ? item.penyimpanan.barang ? item.penyimpanan.barang.kode : '-' : '-' }}
              </template>
              <template #cell(kategori)="{ item }">
                {{ item.barang.kategori ? item.barang.kategori.kategori : '-' }}
              </template>
              <template #cell(penyimpanan)="{ item }">
                <span v-if="item">
                  {{ item.penyimpanan ? item.penyimpanan.barang ? item.penyimpanan.barang.varian_item[0].varian : '-' :
                    '-' }}<br>
                  <small>{{ item.penyimpanan ? item.penyimpanan.barang ? item.penyimpanan.barang.kategori.kategori : '-'
                    : '-' }} / {{
                      item.penyimpanan ? item.penyimpanan.barang ? item.penyimpanan.barang.nama : '-' : '-' }}</small>
                </span>
                <i v-else class="text-danger">Data tidak ada.</i>
              </template>

              <template #cell(actions)="row">
                <!-- <b-button :title="$t('Edit')" size="sm" @click="edit(row.item)" class="mr-1" variant="info">
                  <feather-icon icon="EditIcon" />
                </b-button> -->
                <b-button :title="$t('Delete')" size="sm" @click="remove(row.item)" class="mr-1" variant="danger">
                  <feather-icon icon="TrashIcon" />
                </b-button>
              </template>
            </b-table>
          </b-col>

          <b-col cols="12">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="center" size="sm"
              class="my-0" />
          </b-col>
        </template>
        <template v-else>
          <b-col cols="12">
            <div class="alert alert-danger p-2"><strong>{{ $t('Data tidak ada') }}</strong>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-card>
    <!-- <section>
      <h3>Create XLSX</h3>
      <div>
        <input v-model="sheetName" placeholder="type a new sheet name" />
        <button v-if="sheetName" @click="addSheet">Add Sheet</button>
      </div>

      <div>Sheets: {{ sheets }}</div>

      <xlsx-workbook>
        <xlsx-sheet :collection="sheet.data" v-for="sheet in sheets" :key="sheet.name" :sheet-name="sheet.name" />
        <xlsx-download>
          <button>Download</button>
        </xlsx-download>
      </xlsx-workbook>
    </section> -->
  </b-overlay>
</template>

<script>
import { XlsxRead, XlsxTable, XlsxSheets, XlsxJson, XlsxWorkbook, XlsxSheet, XlsxDownload } from "../../../docs/dist/vue-xlsx.es.js"
// import ModalImport from './Import.vue'
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow, BCard,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton, BButtonGroup,
  BOverlay
} from "bootstrap-vue";
// import ModalDisposal from './components/Modal.vue'

export default {
  components: {
    // ModalDisposal,
    // ModalImport,
    BCardActions, VBTooltip, ToastificationContent,
    BModal,
    BTable,
    BOverlay,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton, BButtonGroup, BCard,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      // modalimport: false,
      file: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [{ name: "Sheet", data: [{ nama: 'Contoh: disposal nama', no_hp_pic: '841982419', instansi: 'PT. heykama', nohp: '08124812894', email: 'owner@heykama.com', top: 'Cash', alamat: 'Bandung, buahbatu', memo: 'Note' }] }],
      collection: [{ a: 1, b: 2 }],
      //
      loading: false,
      showModalAdd: false,
      id: null,
      nama_jabatan: null,
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "desc",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      currentItem: {},
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "tanggal", label: "Tanggal", sortable: true },
        { key: "kode", label: "Barcode", sortable: true },
        { key: "penyimpanan", label: "Barang", sortable: true },
        { key: "type", label: "Tipe", sortable: true },
        // { key: "nohp", label: "No. Handphone", sortable: true },
        { key: "jumlah", label: "Qty", sortable: true },
        { key: "keterangan", label: "Keterangan", sortable: true },
        // { key: "memo", label: "Memo", sortable: true },
        { key: "actions", label: "Aksi" }
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  watch: {
    perPage(value) {
      if (value) {
        this.getData();
      }
    },
    // filter(keyword) {
    //   if (keyword.length > 2 || keyword.length == 0) {
    //     this.getData();
    //   }
    // },
    currentPage(val) {
      if (val) {
        this.getData();
      }
    },
    truncateString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + '...';
      } else {
        return str;
      }
    },
  },
  created() {
    // Set the initial number of items
    this.getData()
  },
  methods: {
    adddisposal() {
      this.$router.push(`/disposal/add`)
    },
    addimport() {
      // this.resetForm()
      this.$bvModal.show('modal-import')
    },
    async submitimport(cek) {

      cek.map((hem) => {
        hem.jenis = 2;
      });
      let payload = cek
      // console.log('cek', cek)
      // if (this.id) {
      //   payload.id = this.id
      // }
      if (this.isOwner || this.isCEO) {
        payload.jenis = 2
      }
      else {
        payload.jenis = 2
      }
      try {
        this.loading = true
        await this.$store.dispatch('disposal/save', payload)
        this.displaySuccess({
          message: 'Berhasil'
        })
        this.getData()
        this.$bvModal.hide('modal-disposal')

        this.loading = false
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    onChange(event) {
      this.file = event.target.files ? event.target.files[0] : null;
    },
    addSheet() {
      this.sheets.push({ name: this.sheetName, data: [...this.collection] });
      this.sheetName = null;
    },
    toggleLoading(val) {
      this.loading = val
    },
    add() {
      this.resetForm()
      // this.$bvModal.show('modal-disposal')
    },
    remove(item) {
      this.$swal({
        title: 'Anda yakin?',
        text: `Data Disposal ${item.tanggal} ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          const payload = {
            // fungsi: 1,
            id: item.id
          }
          this.$store.dispatch('disposal/delete', [payload])
            .then(() => {
              this.displaySuccess({
                message: 'Berhasil'
              })
              this.resetForm()
              this.getData()
            })
            .catch(e => {
              this.displayError(e)
              return false
            })
        }
      })
    },
    resetForm() {
      this.id = null
    },
    async submit(payload) {
      if (this.id) {
        payload.id = this.id
      }
      if (this.isOwner || this.isCEO) {
        payload.jenis = 1
      }
      else {
        payload.jenis = 2
      }
      try {
        this.loading = true
        await this.$store.dispatch('disposal/save', [payload])
        this.displaySuccess({
          message: 'Berhasil'
        })
        this.getData()
        // this.$bvModal.hide('modal-disposal')

        this.loading = false
      }
      catch (e) {
        this.loading = false
        this.displayError(e)
        return false
      }
    },
    edit(item) {
      // this.getDataId(item)
      // this.id = item.id
      this.currentItem = item
      if (this.currentItem) {
        this.$router.push(`/disposal/add/${item.id}`)
        // this.$bvModal.show('modal-disposal')
      }
      // console.log('CEK', this.currentItem)
    },
    async getDataId(item) {
      this.loading = true
      this.$store
        .dispatch("disposal/getDataById", item.id)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.disposal.datas));
          let items_total = this.$store.state.disposal.totals;
          this.items = items
          this.totalRows = items_total
          this.loading = false
        }).catch((e) => {
          this.displayError(e);
          this.loading = false
          return false;
        });
    },
    async getData() {
      this.loading = true
      const perPage = parseInt(this.perPage);
      const currentPage = (parseInt(this.currentPage) - 1) * perPage;
      const params = {
        // search: this.filter ? this.filter : null,
        order: this.sortDesc ? this.sortDesc : 'desc',
        start: currentPage,
        length: this.perPage,
        id_gudang: this.myGudang.id ? this.myGudang.id : this.myGudang.data.id
        // filter_by: "nama_lengkap",
        // disposal_id: this.disposal,
        // id_disposal: this.disposal,
      };
      if (this.filter) {
        params.search = this.filter
      }
      this.$store
        .dispatch("disposal/getData", params)
        .then((response) => {
          let items = JSON.parse(JSON.stringify(this.$store.state.disposal.datas));
          let items_total = this.$store.state.disposal.totals;
          this.items = items
          this.totalRows = items_total
          this.loading = false
        }).catch((e) => {
          this.displayError(e);
          this.loading = false
          return false;
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>